// swahili words
const swahiliWords: string[] = [
    "dashibodi",
    "bidhaa",
    "bidhaa mpya",
    "chukua akiba",
    "rekebisha akiba",
    "orodha ya bidhaa",
    "orodha ya akiba",
    "mauzo",
    "mauzo mapya",
    "agizo jipya",
    "orodha ya mauzo",
    "orodha ya maagizo",
    "ankara mpya ya proforma",
    "orodha ya ankara ya proforma",
    "matumizi",
    "matumizi mapya",
    "orodha ya matumizi",
    "aina mpya ya matumizi",
    "orodha ya aina za matumizi",
    "ununuzi",
    "ununuzi mpya",
    "orodha ya ununuzi",
    "mteja",
    "mteja mpya",
    "orodha ya wateja",
    "deni",
    "deni jipya",
    "orodha ya madeni",
    "orodha ya historia ya madeni",
    "ujumbe",
    "ujumbe mpya",
    "orodha ya ujumbe",
    "ripoti",
    "ripoti mpya",
    "takwimu",
    "report ya jumla",
    "mtumiaji",
    "mtumiaji mpya",
    "orodha ya watumiaji",
    "jukumu",
    "jukumu jipya",
    "orodha ya majukumu",
    "malipo",
    "duka",
    "duka jipya",
    "orodha ya maduka",
    "orodha ya shughuli",
    "stoo",
    "stoo mpya",
    "orodha ya stoo",
    "bidhaa mpya ya stoo",
    "orodha ya bidhaa za stoo",
    "wasifu",
    "tazama wasifu",
    "badilisha neno la siri",
    "ingia",
    "ondoka",
    "Lo! ukurasa haujapatikana",
    "ukurasa haujapatikana",
    "Samahani, lakini ukurasa unaotafuta haupatikani. tafadhali, hakikisha umeandika url sahihi na una ruhusa ya kutazama hii rasilimali",
    "tengeneza",
    "inahitajika",
    "jina",
    "ingiza jina",
    "bei ya kununua",
    "ingiza bei ya ununuzi",
    "bei ya kuuzia",
    "ingiza bei ya kuuza",
    "akiba iliyopo",
    "ingiza akiba iliyopo",
    "tahadhari ya akiba",
    "ingiza tahadhari ya akiba",
    "pakia au ingiza kutoka excel",
    "ingiza bidhaa kutoka excel",
    "chagua faili",
    "hakuna faili iliyochaguliwa",
    "faili inahitajika au faili haina bidhaa",
    "pakua mfano wa excel",
    "funga",
    "ingiza",
    "orodha ya zinazokaribia kuisha",
    "orodha iliyofutwa",
    "orodha iliyopo",
    "orodha ya zilishoisha",
    "mpangilio wa kupanda",
    "mpangilio wa kushuka",
    "wakati iliyotengenezwa",
    "akiba",
    "kikomo cha",
    "kikomo kwa",
    "ingiza neno kuu la utafutaji",
    "kuuzwa",
    "hali",
    "chaguzi",
    "inapatikana",
    "hariri",
    "tazama",
    "hariri bidhaa",
    "rekebisha",
    "taarifa ya bidhaa",
    "jumla iliyouzwa",
    "tahadhari ya akiba",
    "imetengenezwa na",
    "wakati iliyotengenezwa",
    "imerekebishwa na",
    "wakati iliyorekebishwa",
    "futa",
    "imefutwa",
    "imefutwa na",
    "wakati iliyofutwa",
    "bidhaa iliyofutwa",
    "rejesha",
    "imefanikiwa kurekebishwa",
    "rekebisha",
    "rekebisha akiba",
    "ingiza bidhaa",
    "akiba iliyopo",
    "aina",
    "akiba ya kurekebisha",
    "ingiza akiba ya kurekebisha",
    "akiba baada ya marekebisho",
    "orodha ya akiba",
    "fedha taslimu",
    "mkopo",
    "A/I",
    "bidhaa haipo",
    "idadi",
    "ingiza idadi",
    "mteja (hiari)",
    "ingiza mteja",
    "jina la mteja ni lazima",
    "mteja hayupo",
    "kuna kosa",
    "inakagua",
    "imefanikiwa kutengenezwa",
    "kapu",
    "bidhaa imeondolewa kwenye kapu",
    "kiasi",
    "punguzo",
    "ondoa",
    "jumla",
    "chapa",
    "hifadhi",
    "haiwezi kuwa kubwa kuliko bei ya kuuza",
    "zilizoisha",
    "zilizokaribia kuisha",
    "zilizopo",
    "zinakaribia kuisha",
    "data tayari zipo",
    "orodha",
    "bidhaa inahitajika",
    "ongeza",
    "punguza",
    "akiba baada ya marekebisho haiwezi kuwa chini ya 0",
    "kabla ya marekebisho",
    "marekebisho",
    "baada ya marekebisho",
    "wakati",
    "imeongezeka",
    "imepungua",
    "taarifa ya marekebisho",
    "hakuna akiba ya kutosha",
    "faida",
    "hasara",
    "taarifa ya mauzo",
    "faida ya ziada",
    "hakuna mauzo yaliyofanyika",
    "uthibitisho wa mauzo",
    "una uhakika unataka kufuta mauzo haya?",
    "una uhakika unataka kufuta mauzo haya?",
    "una uhakika unataka kurejesha mauzo haya?",
    "una uhakika unataka kurejesha mauzo haya?",
    "kataa",
    "kubali",
    "orodha ya maagizo",
    "hakuna agizo lililopatikana",
    "maagizo",
    "nambari",
    "bidhaa",
    "orodha ya maagizo ya leo",
    "tazama maagizo",
    "taarifa ya maagizo",
    "ankara mpya",
    "orodha ya ankara",
    "orodha ya leo ya ankara ya proforma",
    "tazama ankara ya proforma",
    "taarifa ya ankara ya proforma",
    "mauzo yamefutwa",
    "maagizo yamefutwa",
    "uthibitisho wa maagizo",
    "una uhakika unataka kufuta maagizo haya?",
    "una uhakika unataka kufuta maagizo haya?",
    "una uhakika unataka kurejesha maagizo haya?",
    "una uhakika unataka kurejesha maagizo haya?",
    "imeshindwa kurekebisha mauzo",
    "imeshindwa kurekebisha maagizo",
    "ankara ya proforma",
    "hakuna aina ya matumizi iliyopatikana",
    "chagua aina",
    "wakati",
    "jumla ya kiasi",
    "ingiza jumla ya kiasi",
    "kiasi kilicholipwa",
    "ingiza kiasi kilicholipwa",
    "maelezo",
    "weka maelezo",
    "haiwezi kuwa kubwa kuliko jumla ya kiasi",
    "haiwezi kuwa pungufu au sawa na sifuri",
    "matumizi yamefutwa",
    "hakuna matumizi yaliyopatikana",
    "matumizi",
    "orodha ya leo",
    "orodha iliyolipwa",
    "orodha ambayo haijalipwa",
    "kiasi kilichobaki",
    "uthibitisho wa matumizi",
    "una uhakika unataka kufuta matumizi haya?",
    "una uhakika unataka kufuta matumizi haya?",
    "una uhakika unataka kurejesha matumizi haya?",
    "una uhakika unataka kurejesha matumizi haya?",
    "hariri matumizi",
    "taarifa ya matumizi",
    "tazama matumizi",
    "matumizi yamerejeshwa",
    "tarehe batili",
    "uthibitisho wa aina ya matumizi",
    "una uhakika unataka kufuta aina hii ya matumizi?",
    "una uhakika unataka kufuta aina hii ya matumizi?",
    "una uhakika unataka kurejesha aina hii ya matumizi?",
    "una uhakika unataka kurejesha aina hii ya matumizi?",
    "taarifa ya aina ya matumizi",
    "tazama aina ya matumizi",
    "aina za matumizi",
    "hariri aina ya matumizi",
    "aina ya matumizi imefutwa",
    "imeshindwa kurekebisha zote",
    "aina ya matumizi imerejeshwa",
    "hai",
    "matumizi yamerejeshwa",
    "matumizi yamefutwa",
    "aina za matumizi zimerejeshwa",
    "aina za matumizi zimefutwa",
    "akiba iliyonunuliwa",
    "ingiza akiba iliyonunuliwa",
    "haiwezi kuwa chini ya bei ya kununua",
    "tahadhari ya akiba ya bidhaa",
    "imeshindwa kuleta",
    "imelipwa",
    "ununuzi",
    "uthibitisho wa ununuzi",
    "una uhakika unataka kufuta ununuzi huu?",
    "una uhakika unataka kufuta ununuzi huu?",
    "una uhakika unataka kurejesha ununuzi huu?",
    "una uhakika unataka kurejesha ununuzi huu?",
    "taarifa ya ununuzi",
    "ununuzi umefutwa",
    "tazama ununuzi",
    "hariri ununuzi",
    "ununuzi umefutwa",
    "nambari ya simu",
    "ingiza nambari ya simu",
    "mkoa",
    "chagua mkoa",
    "eneo",
    "ingiza eneo",
    "mtaa",
    "ingiza mtaa",
    "nambari ya tin",
    "ingiza nambari ya tin",
    "ingiza wateja kutoka excel",
    "pakua mfano wa excel",
    "wateja",
    "orodha ya wenye nambari ya tin",
    "orodha ya wasio na nambari ya tin",
    "pakua",
    "uthibitisho wa mteja",
    "una uhakika unataka kufuta mteja huyu?",
    "una uhakika unataka kufuta wateja hawa?",
    "una uhakika unataka kurejesha mteja huyu?",
    "una uhakika unataka kurejesha wateja hawa?",
    "mteja amefutwa",
    "wateja wamefutwa",
    "mteja amerejeshwa",
    "wateja wamerejeshwa",
    "imeshindwa kurekebisha mteja",
    "tazama mteja",
    "rekebisha mteja",
    "taarifa ya mteja",
    "mteja anadaiwa",
    "duka linadaiwa",
    "deni limetengenezwa",
    "madeni",
    "orodha ya madeni ya wateja",
    "orodha ya madeni ya mauzo",
    "orodha ya madeni ya duka",
    "haijalipwa",
    "rekebisha deni",
    "deni limerekebishwa",
    "uthibitisho wa deni",
    "una uhakika unataka kufuta deni hili?",
    "una uhakika unataka kufuta madeni haya?",
    "una uhakika unataka kurejesha deni hili?",
    "una uhakika unataka kurejesha madeni haya?",
    "deni limefutwa",
    "madeni yamefutwa",
    "deni limerejeshwa",
    "madeni yamerejeshwa",
    "hakuna deni lililopatikana",
    "taarifa ya deni",
    "malipo ya deni",
    "tazama deni",
    "fanya malipo",
    "kiasi kilicholipwa",
    "ingiza kiasi kilicholipwa",
    "kiasi kilicholipwa ni kikubwa kuliko kiasi kilichobaki",
    "deni la mauzo",
    "hakuna historia ya deni iliyopatikana",
    "historia ya madeni",
    "orodha ya historia ya madeni",
    "uthibitisho wa historia ya deni",
    "una uhakika unataka kufuta historia ya deni hili?",
    "una uhakika unataka kufuta historia ya madeni haya?",
    "historia ya deni imefutwa ",
    "historia ya madeni imefutwa",
    "wapokeaji",
    "chagua aina ya mpokeaji",
    "akiba",
    "andika ujumbe",
    "chagua angalau mpokeaji 1",
    "twakimu za repoti",
    "watumiaji wa mfumo",
    "gharama",
    "tuma ujumbe",
    "ujumbe umetumwa",
    "nambari ya simu lazima iwe na tarakimu 10",
    "ujumbe",
    "akiba",
    "malipo",
    "mauzo",
    "tarehe ya kuanza (kutoka)",
    "tarehe ya mwisho (hadi)",
    "duka (hiari)",
    "mtumiaji (hiari)",
    "report iliyofutwa",
    "report ya data iliyofutwa",
    "ingiza mtumiaji",
    "ingiza duka",
    "ripoti ya akiba",
    "ripoti ya historia ya madeni",
    "ripoti ya madeni",
    "ripoti ya matumizi",
    "ripoti ya maagizo",
    "ripoti ya malipo",
    "ripoti ya ununuzi",
    "ripoti ya mauzo",
    "aina ya akiba (hiari)",
    "chagua aina ya akiba",
    "bidhaa (hiari)",
    "bidhaa ya stoo (hiari)",
    "ingiza bidhaa ya stoo",
    "akiba iliyoongezeka",
    "akiba iliyopungua",
    "hakuna akiba iliyopatikana",
    "bidhaa ya stoo haipo",
    "chapa ripoti",
    "ripoti ya akiba iliyofutwa",
    "ripoti ya historia ya madeni yaliyofutwa",
    "ripoti ya madeni yaliyofutwa",
    "ripoti ya matumizi yaliyofutwa",
    "ripoti ya maagizo yaliyofutwa",
    "ripoti ya malipo yaliyofutwa",
    "ripoti ya ununuzi uliofutwa",
    "ripoti ya mauzo yaliyofutwa",
    "hali (hiari)",
    "chagua hali",
    "aina ya matumizi (hiari)",
    "ingiza aina ya matumizi",
    "hakuna malipo yaliyopatikana",
    "aina ya malipo",
    "chagua aina ya malipo",
    "malipo ya mwezi",
    "mengineo",
    "ununuzi wa sms",
    "ufungaji wa mfumo",
    "hali ya mauzo (hiari)",
    "chagua hali ya mauzo",
    "mauzo ya fedha taslimu",
    "mauzo ya mikopo",
    "hakuna historia ya deni iliyopatikana",
    "hakuna ununuzi uliopatikana",
    "aina ya ripoti",
    "chagua aina ya ripoti",
    "ingiza jina la duka",
    "ingiza jina la mtumiaji",
    "takwimu za historia ya madeni",
    "takwimu za madeni",
    "takwimu za matumizi",
    "takwimu za malipo",
    "takwimu za ununuzi",
    "takwimu za mauzo",
    "ingiza jina la aina ya matumizi",
    "ingiza jina la bidhaa",
    "ingiza jina la mteja",
    "ripoti mpya ya jumla",
    "kipengele",
    "mapato",
    "matumizi",
    "madeni ya wateja",
    "madeni ya duka",
    "chagua duka",
    "chagua jukumu",
    "aina ya akaunti",
    "chagua aina ya akaunti",
    "mmiliki wa duka",
    "mfanyakazi wa duka",
    "jina la mtumiaji",
    "igiza jina la mtumiaji",
    "akaunti haina usalama",
    "akaunti ina usalama",
    "badilisha mipangilio ya duka",
    "faili imepakiwa",
    "hakuna duka lililopatikana",
    "watumiaji",
    "orodha ya wasaidizi",
    "orodha ya wafanyakazi",
    "orodha ya wasiothibitishwa",
    "orodha ya wamiliki",
    "orodha ya waliothibitishwa",
    "orodha ya akaunti usalama",
    "orodha ya akaunti zisizo salama",
    "imethibitishwa",
    "salama",
    "hapana",
    "ndio",
    "nambari ya simu imethibitishwa",
    "hariri mtumiaji",
    "mtumiaji ni salama",
    "taarifa ya mtumiaji",
    "uthibitisho wa mtumiaji",
    "tazama mtumiaji",
    "una uhakika unataka kumfuta mtumiaji?",
    "una uhakika unataka kumrejesha mtumiaji?",
    "hakuna mtumiaji aliyepatikana",
    "mtumiaji amefutwa",
    "mtumiaji amerejeshwa",
    "tazama duka",
    "rekebisha duka",
    "orodhesha duka",
    "tengeneza duka",
    "futa duka",
    "badilisha duka",
    "orodhesha shughuli",
    "tazama menyu ya duka",
    "orodhesha mteja",
    "tengeneza mteja",
    "futa mteja",
    "tazama menyu ya mteja",
    "tazama dashibodi",
    "tazama menyu ya dashibodi",
    "tazama deni",
    "orodhesha deni",
    "tengeneza deni",
    "futa deni",
    "tazama menyu ya deni",
    "tazama historia ya deni",
    "tengeneza malipo ya deni",
    "futa historia ya deni",
    "historia ya deni",
    "orodhesha matumizi",
    "tengeneza matumizi",
    "futa matumizi",
    "tazama menyu ya matumizi",
    "aina ya matumizi",
    "orodhesha aina ya matumizi",
    "tengeneza aina ya matumizi",
    "futa aina ya matumizi",
    "kwa ujumla",
    "rejesha zilizofutwa",
    "orodhesha ujumbe",
    "tengeneza ujumbe",
    "tazama menyu ya ujumbe",
    "agizo",
    "tengeneza agizo",
    "futa agizo",
    "tazama malipo",
    "orodhesha malipo",
    "tengeneza malipo",
    "ghairi malipo",
    "tazama menyu ya malipo",
    "tazama bidhaa",
    "orodhesha bidhaa",
    "tengeneza bidhaa",
    "futa bidhaa",
    "tazama menyu ya bidhaa",
    "tazama bei ya ununuzi",
    "tengeneza ankara ya proforma",
    "futa ankara ya proforma",
    "orodhesha ununuzi",
    "tengeneza ununuzi",
    "futa ununuzi",
    "tazama menyu ya ununuzi",
    "tengeneza repoti",
    "pakua ripoti",
    "tazama menyu ya ripoti",
    "tazama ripoti ya jumla",
    "tazama ripoti ya takwimu",
    "tazama ripoti ya data zilizofutwa",
    "tazama jukumu",
    "rekebisha jukumu",
    "orodhesha jukumu",
    "tengeneza jukumu",
    "futa jukumu",
    "tazama menyu ya jukumu",
    "tazama mauzo",
    "orodhesha mauzo",
    "tazama hasara",
    "tengeneza mauzo",
    "futa mauzo",
    "tazama faida",
    "tazama punguzo",
    "tazama menyu ya mauzo",
    "tazama akiba",
    "hakiki akiba",
    "orodhesha marekebisho ya akiba",
    "tazama marekebisho ya akiba",
    "tazama stoo",
    "rekebisha stoo",
    "orodhesha stoo",
    "tengeneza stoo",
    "futa stoo",
    "tazama menyu ya stoo",
    "bidhaa za stoo",
    "tazama bidhaa za stoo",
    "rekebisha bidhaa za stoo",
    "orodhesha bidhaa za stoo",
    "tengeneza bidhaa za stoo",
    "futa bidhaa za stoo",
    "orodhesha mtumiaji",
    "tengeneza mtumiaji",
    "futa mtumiaji",
    "tazama menyu ya mtumiaji",
    "ruhusa",
    "majukumu",
    "uthibitisho wa jukumu",
    "una uhakika unataka kufuta jukumu hili?",
    "una uhakika unataka kufuta majukumu haya?",
    "una uhakika unataka kurejesha jukumu hili?",
    "una uhakika unataka kurejesha majukumu haya?",
    "jukumu limefutwa",
    "jukumu limerejeshwa",
    "chagua angalau ruhusa 1",
    "majukumu yamefutwa",
    "majukumu yamerejeshwa",
    "taarifa ya jukumu",
    "orodha hai",
    "orodha iliyokataliwa",
    "orodha ya malipo ya mwezi",
    "orodha nyingine",
    "orodha ya malipo",
    "orodha ya ununuzi wa sms",
    "orodha ya malipo ya ufungaji wa mfumo",
    "orodha ya usajili wa vendor name",
    "kataa",
    "taarifa ya malipo",
    "uthibitisho wa malipo",
    "una uhakika unataka kukataa malipo haya",
    "una uhakika unataka kukataa malipo haya",
    "malipo yamekataliwa",
    "malipo yamekataliwa",
    "malipo ya mwezi yanahitajika",
    "nambari ya tin (hiari)",
    "ingiza nambari ya tin",
    "api key (hiari)",
    "ingiza api key",
    "vendor (hiari)",
    "ingiza vendor",
    "malipo ya mwezi (hiari)",
    "ingiza malipo ya mwezi",
    "nembo",
    "maduka",
    "siku",
    "orodha iliyolipa",
    "orodha isiyolipa",
    "uthibitisho wa duka",
    "una uhakika unataka kufuta duka hili?",
    "una uhakika unataka kufuta maduka haya?",
    "una uhakika unataka kurejesha duka hili?",
    "una uhakika unataka kurejesha maduka haya?",
    "duka limefutwa",
    "maduka yamefutwa",
    "duka limerejeshwa",
    "maduka yamerejeshwa",
    "mipangilio",
    "malipo mapya",
    "taarifa ya duka",
    "hamia kwenye hili duka",
    "malipo ya mwezi",
    "siku zilizobaki",
    "kipengele",
    "urekebishaji",
    "utengenezaji",
    "ufutaji",
    "mtoa huduma",
    "jina la akaunti",
    "nambari ya akaunti",
    "ongeza njia mpya ya malipo",
    "njia mpya ya malipo",
    "chagua mtoa huduma",
    "ingiza jina la akaunti",
    "ingiza nambari ya akaunti",
    "ongeza njia",
    "slp",
    "ingiza slp",
    "barua pepe",
    "ingiza barua pepe",
    "tovuti",
    "ingiza tovuti",
    "muda wa kufungua",
    "muda wa kufunga",
    "kauli mbiu ya duka",
    "ingiza kauli mbiu ya duka",
    "maelezo ya ankara ya proforma",
    "ingiza maelezo ya ankara ya proforma",
    "taarifa za sms",
    "mauzo ya mteja au risiti ya agizo",
    "ripoti ya madeni ya kila siku",
    "ripoti ya kila siku",
    "akiba ya bidhaa",
    "kumbusha madeni ya mteja",
    "akiba ya bidhaa ya stoo",
    "data za duka",
    "idadi",
    "shughuli",
    "bidhaa za stoo",
    "stoo",
    "orodha ya utengenezaji",
    "orodha ya ufutaji",
    "orodha ya urekebishaji",
    "shughuli",
    "endelea",
    "rekebisha wasifu",
    "neno la siri la zamani",
    "ingiza neno la siri la zamani",
    "neno la siri la zamani ni lazima liwe na angalau herufi 8",
    "neno la siri jipya ni lazima liwe na angalau herufi 8",
    "uthibitishaji wa neno la siri ni lazima uwe na herufi angalau 8",
    "neno la siri jipya",
    "uthibitisho wa neno la siri jipya",
    "neno la siri halilingani",
    "ingiza neno la siri jipya",
    "neno la siri la zamani si sahihi",
    "karibu tena!",
    "akaunti",
    "andika jina la mtumiaji au nambari ya simu",
    "neno la siri",
    "ingiza neno la siri",
    "umesahu neno la siri",
    "akaunti haipo",
    "neno la siri sio sahihi",
    "neno la siri ni lazima liwe na angalau herufi 4",
    "wasiliana nasi",
    "tayari una akaunti",
    "tuma nambari",
    "imeshindwa kutuma nambari ya kuthibitisha",
    "umesahau neno la siri",
    "nambari ya kuthibitisha imetumwa kwa nambari yako ya simu",
    "nambari ya uthibitishaji",
    "nambari ya uthibitisho",
    "ingiza nambari iliyotumwa kwa nambari yako ya simu",
    "thibitisha",
    "nambari ya uthibitisho ni lazima iwe na tarakimu 6",
    "nambari ya uthibitisho sio sahihi",
    "mlinganisho wa data",
    "muhtasari",
    "sekunde chache zilizopita",
    "dakika 1 imepita",
    "dakika 2 zilizopita",
    "dakika 4 zilizopita",
    "dakika 5 zilizopita",
    "dakika 6 zilizopita",
    "dakika 7 zilizopita",
    "dakika 8 zilizopita",
    "dakika 9 zilizopita",
    "dakika 10 zilizopita",
    "dakika 11 zilizopita",
    "dakika 12 zilizopita",
    "dakika 13 zilizopita",
    "dakika 14 zilizopita",
    "dakika 15 zilizopita",
    "dakika 16 zilizopita",
    "dakika 17 zilizopita",
    "dakika 3 zilizopita",
    "dakika 18 zilizopita",
    "dakika 19 zilizopita",
    "dakika 20 zilizopita",
    "dakika 21 zilizopita",
    "dakika 22 zilizopita",
    "dakika 23 zilizopita",
    "dakika 24 zilizopita",
    "dakika 25 zilizopita",
    "dakika 26 zilizopita",
    "dakika 27 zilizopita",
    "dakika 28 zilizopita",
    "dakika 29 zilizopita",
    "dakika 30 zilizopita",
    "dakika 31 zilizopita",
    "dakika 32 zilizopita",
    "dakika 33 zilizopita",
    "dakika 34 zilizopita",
    "dakika 35 zilizopita",
    "dakika 36 zilizopita",
    "dakika 37 zilizopita",
    "dakika 38 zilizopita",
    "dakika 39 zilizopita",
    "dakika 40 zilizopita",
    "dakika 41 zilizopita",
    "dakika 42 zilizopita",
    "dakika 43 zilizopita",
    "dakika 44 zilizopita",
    "dakika 45 zilizopita",
    "dakika 46 zilizopita",
    "dakika 47 zilizopita",
    "dakika 48 zilizopita",
    "dakika 49 zilizopita",
    "dakika 50 zilizopita",
    "dakika 51 zilizopita",
    "dakika 52 zilizopita",
    "dakika 53 zilizopita",
    "dakika 54 zilizopita",
    "dakika 55 zilizopita",
    "dakika 56 zilizopita",
    "dakika 57 zilizopita",
    "dakika 58 zilizopita",
    "dakika 59 zilizopita",
    "saa moja lililopita",
    "masaa 2 yaliyopita",
    "masaa 3 yaliyopita",
    "masaa 4 yaliyopita",
    "masaa 5 yaliyopita",
    "masaa 6 yaliyopita",
    "masaa 7 yaliyopita",
    "masaa 8 yaliyopita",
    "masaa 9 yaliyopita",
    "masaa 10 yaliyopita",
    "masaa 11 yaliyopita",
    "masaa 12 yaliyopita",
    "masaa 13 yaliyopita",
    "masaa 14 yaliyopita",
    "masaa 15 yaliyopita",
    "masaa 16 yaliyopita",
    "masaa 17 yaliyopita",
    "masaa 18 yaliyopita",
    "masaa 19 yaliyopita",
    "masaa 20 yaliyopita",
    "masaa 21 yaliyopita",
    "masaa 22 yaliyopita",
    "masaa 23 yaliyopita",
    "siku moja imepita",
    "siku 2 zilizopita",
    "siku 3 zilizopita",
    "siku 4 zilizopita",
    "siku 5 zilizopita",
    "siku 6 zilizopita",
    "siku 7 zilizopita",
    "siku 8 zilizopita",
    "siku 9 zilizopita",
    "siku 10 zilizopita",
    "siku 11 zilizopita",
    "siku 12 zilizopita",
    "siku 13 zilizopita",
    "siku 14 zilizopita",
    "siku 15 zilizopita",
    "siku 16 zilizopita",
    "siku 17 zilizopita",
    "siku 18 zilizopita",
    "siku 19 zilizopita",
    "siku 20 zilizopita",
    "siku 21 zilizopita",
    "siku 22 zilizopita",
    "siku 23 zilizopita",
    "siku 24 zilizopita",
    "siku 25 zilizopita",
    "siku 26 zilizopita",
    "siku 27 zilizopita",
    "siku 28 zilizopita",
    "siku 29 zilizopita",
    "siku 30 zilizopita",
    "mwezi 1 uliopita",
    "miezi 2 iliyopita",
    "miezi 3 iliyopita",
    "miezi 4 iliyopita",
    "miezi 5 iliyopita",
    "miezi 6 iliyopita",
    "miezi 7 iliyopita",
    "miezi 8 iliyopita",
    "miezi 9 iliyopita",
    "miezi 10 iliyopita",
    "miezi 11 iliyopita",
    "miezi 12 iliyopita",
    "mwaka 1 uliopita",
    "miaka 2 iliopita",
    "miaka 3 iliopita",
    "miaka 4 iliopita",
    "miaka 5 iliopita",
    "miaka 6 iliopita",
    "miaka 7 iliopita",
    "miaka 8 iliopita",
    "miaka 9 iliopita",
    "miaka 10 iliopita",
    "data mpya ilitengenezwa",
    "data ilirekebishwa",
    "data ilifutwa kwa muda",
    "data ilifutwa milele",
    "kweli",
    "januari",
    "februari",
    "machi",
    "aprili",
    "mei",
    "juni",
    "julai",
    "agosti",
    "septemba",
    "oktoba",
    "novemba",
    "desemba",
    "faili inahitajika",
    "malipo ya seva",
    "uthibitisho wa stoo",
    "una uhakika unataka kufuta stoo hii?",
    "una uhakika unataka kufuta stoo hizi?",
    "una uhakika unataka kurejesha stoo hii?",
    "una uhakika unataka kurejesha stoo hizi?",
    "stoo imefutwa",
    "stoo zimefutwa",
    "stoo imerejeshwa",
    "stoo zimerejeshwa",
    "taarifa ya stoo",
    "hakuna bidhaa ya stoo iliyopatikana",
    "chagua stoo",
    "pakua mfano wa excel",
    "orodha iliyopungua",
    "orodha iliyoongezeka",
    "hakuna bidhaa iliyopatikana",
    "hakuna stoo iliyopatikana",
    "hakuna jukumu lililopatikana",
    "hakuna mteja aliyepatikana",
    "nambari za api ni batili",
    "tin lazima iwe na tarakimu 9",
    "haipo",
    "njia za malipo",
    "kiingereza",
    "lugha",
    "upakiaji wa mfumo umewezeshwa",
    "upakiaji wa mfumo haujawezeshwa",
    "faida ya mauzo",
    "vizaidizi",
    "",
    "",
    "",
    "matumizi yasiyolipwa",
    "ununuzi usiolipwa",
    "kiswahili",
    "orodha iliyoibiwa",
    "orodha ya bidhaa zilizoibiwa",
    "mdaiwa",
    "mdai",
    "haijathibitishwa",
    "idadi iliyochukuliwa"
]

export default swahiliWords